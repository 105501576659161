<template>
    <section id="About">
        <div class="p-8 sm:p-12 lg:p-16 bg-neutral-200">
            <div class="max-w-screen-xl mx-auto">
                
                <!-- About Section Header -->
                <div class="mb-8 space-y-4 lg:space-y-6 md:mb-12">
                    <h2 class="text-4xl font-bold xl:text-5xl text-neutral-900">So what is Quick Studio?</h2>
                    <div class="grid lg:grid-cols-12">
                        <div class="col-span-12 sm:col-span-6 lg:col-span-4">
                            <div class="text-sm font-sm xl:text-lg text-neutral-800">
                                <p>Quick Studio is the latest innovation in the world of music recording. Just by signing up, you will be able to:</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <!-- About Items -->
                <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-x-20 lg:gap-10 xl:gap-24 lg:grid-cols-3">

                    <!-- About Item -->
                    <div class="space-y-2 xl:space-y-4">
                        <div class="text-lg font-bold xl:text-2xl text-neutral-800">Record</div>
                        <div class="text-sm xl:text-lg text-neutral-700">Record instruments or voice via your device's microphone or your interface, just choose the audio input and go! </div>
                    </div>

                    <!-- About Item -->
                    <div class="space-y-2 xl:space-y-4">
                        <div class="text-lg font-bold xl:text-2xl text-neutral-800">Collaborate</div>
                        <div class="text-sm xl:text-lg text-neutral-700">Invite bandmates and collaborators on the same project at the same time, stop sending files back and forth.</div>
                    </div>

                    <!-- About Item -->
                    <div class="space-y-2 xl:space-y-4">
                        <div class="text-lg font-bold xl:text-2xl text-neutral-800">Play Live</div>
                        <div class="text-sm xl:text-lg text-neutral-700">Jamming is part of the creative process, so play live with your collaborators. So easy your bassist can probably do it!</div>
                    </div>
                    
                </div>

            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'AboutBlock'
    }
</script>