<template>
    <section id="Values">
        <div class="p-8 sm:p-12 lg:p-16">
            <div class="max-w-screen-xl mx-auto">
                <div class="flex flex-col-reverse gap-8 sm:gap-0 sm:grid sm:grid-cols-8 lg:grid-cols-12">

                    <!-- Values Area -->
                    <div class="col-span-4 lg:col-span-5">
                        <div class="flex flex-col gap-8">

                            <!-- Block Heading -->
                            <div class="space-y-4 lg:space-y-6">
                                <!-- Title -->
                                <h2 class="text-4xl font-bold xl:text-5xl text-neutral-900">Why should I care?</h2>
                                <!-- Intro Copy -->
                                <div class="grid lg:grid-cols-5">
                                    <div class="col-span-5 xl:col-span-4">
                                        <div class="text-base font-sm xl:text-lg text-neutral-800">
                                            <p>In a market full of recording software that look all the same, Quick Studio aims to stand out by 2 core values.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Values -->
                            <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-4 lg:grid-cols-2 lg:gap-6 xl:gap-10">
                                
                                <!-- Value Item -->
                                <div class="space-y-2 md:col-span-3 lg:col-span-1">
                                    <div class="flex items-center gap-4">
                                        <svg class="w-6 h-6 text-secondary-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M18 3a1 1 0 00-1.196-.98l-10 2A1 1 0 006 5v9.114A4.369 4.369 0 005 14c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V7.82l8-1.6v5.894A4.37 4.37 0 0015 12c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V3z"></path></svg>
                                        <div class="font-bold xl:text-lg text-neutral-900">Simplification</div>
                                    </div>
                                    <div class="tex-base lg:text-sm xl:text-base text-neutral-700">Away from the complexity of music recording software, Quick Studio always has ease of use in mind.</div>
                                </div>

                                <!-- Value Item -->
                                <div class="space-y-2 md:col-span-3 lg:col-span-1">
                                    <div class="flex items-center gap-4">
                                        <svg class="w-6 h-6 text-secondary-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path></svg>
                                    <div class="font-bold xl:text-lg text-neutral-900">Collaboration</div>
                                    </div>
                                    <div class="tex-base lg:text-sm xl:text-base text-neutral-700">Music creation is often the result of collaboration, therefore it should be embraced.</div>
                                </div>

                            </div>

                            <!-- CTA -->
                            <div>
                                <a class="inline-block px-6 py-2 text-base font-semibold transition border rounded-sm ring-primary-200 focus:ring-2 hover:bg-primary-400 text-primary-700 bg-primary border-primary-600" href="#">Learn more →</a>
                            </div>
                        </div>
                    </div>

                    <!-- Screenshot Area -->
                    <div class="col-span-4 lg:col-span-6 lg:col-start-7">
                        <div class="flex flex-col justify-center h-full lg:justify-center">
                            <picture>
                                <img class="w-full h-auto sm:ml-4 lg:ml-0" loading="lazy" src="@/assets/screenshot.png" alt="Screenshot">
                            </picture>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'ValuesBlock'
    }
</script>