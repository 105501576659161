<template>
    <section id="Testimonials">
        <div class="p-8 sm:p-12 lg:p-16 bg-neutral-200" >
            <div class="max-w-screen-xl mx-auto">
                
                <!-- Block Heading -->
                <div class="mb-8 space-y-4 lg:space-y-6 md:mb-12">
                    <!-- Title -->
                    <h2 class="text-4xl font-bold xl:text-5xl text-neutral-900">Honestly, who cares?</h2>
                    <!-- Intro Copy -->
                    <div class="grid lg:grid-cols-12">
                        <div class="col-span-12 sm:col-span-6 xl:col-span-4">
                            <div class="text-base font-sm xl:text-lg text-neutral-800">
                                <p>Tough crowd isn't it? Well don't take my word for it. Here's a bunch of reviews I totally didn't just make up as I was writing this introduction.</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <!-- Testimonial Area -->
                <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-x-20 lg:gap-10 xl:gap-24 lg:grid-cols-3">

                    <!-- Testimonial Item -->
                    <div class="mb-4 space-y-2 lg:space-y-4">
                        <div class="flex gap-4">
                            <img class="w-16 h-16 rounded-full lg:w-20 lg:h-20" loading="lazy" src="@/assets/users/joanna.jpeg" alt="Person">
                            <div class="flex flex-col justify-between">
                                <div class="text-xs text-neutral-700">26 years old</div>
                                <div class="text-base font-bold xl:text-lg text-neutral-900">Joanna Christie</div>
                                <div class="text-sm xl:text-base text-neutral-800">Amateur Musician</div>
                            </div>
                        </div>
                        <div class="text-base xl:text-lg text-neutral-700">"I had been meaning to start a project with a friend from abroad, and building demos on distance has made it so much easier."</div>
                    </div>
                    
                    <!-- Testimonial Item -->
                    <div class="mb-4 space-y-2 lg:space-y-4">
                        <div class="flex gap-4">
                            <img class="w-16 h-16 rounded-full lg:w-20 lg:h-20" loading="lazy" src="@/assets/users/kevin.jpeg" alt="Kevin">
                            <div class="flex flex-col justify-between">
                                <div class="text-xs text-neutral-700">22 years old</div>
                                <div class="text-base font-bold xl:text-lg text-neutral-900">Kevin Hernandez</div>
                                <div class="text-sm xl:text-base text-neutral-800">Band Member</div>
                            </div>
                        </div>
                        <div class="text-base xl:text-lg text-neutral-700">"Quick Studio has been perfect for playing with my bandmates during lockdown, we finished our EP and will launch it soon!"</div>
                    </div>
                    
                    <!-- Testimonial Item -->
                    <div class="mb-4 space-y-2 lg:space-y-4">
                        <div class="flex gap-4">
                            <img class="w-16 h-16 rounded-full lg:w-20 lg:h-20" loading="lazy" src="@/assets/users/adrienne.jpeg" alt="Person">
                            <div class="flex flex-col justify-between">
                                <div class="text-xs text-neutral-700">24 years old</div>
                                <div class="text-base font-bold xl:text-lg text-neutral-900">Adrienne Gardner</div>
                                <div class="text-sm xl:text-base text-neutral-800">Studio Musician</div>
                            </div>
                        </div>
                        <div class="text-base xl:text-lg text-neutral-700">"I wanted to get my sister started in the world of music recording, and this has been the perfect way to do so!"</div>
                    </div>
                    
                </div>
                
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'TestimonialsBlock'
    }
</script>