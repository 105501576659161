<template>
    <section id="Hero">
        <div class="p-8 sm:p-12 lg:p-16">
            <div class="max-w-screen-xl mx-auto">
                <div class="flex flex-col gap-8 sm:gap-0 sm:grid sm:grid-cols-8 lg:grid-cols-12">

                    <!-- Hero Text -->
                    <div class="col-span-3 lg:col-span-5">
                        <div class="flex flex-col gap-6 lg:gap-8">
                            <h1 class="text-4xl font-bold leading-tight xl:text-5xl text-neutral-900">Connect. Play. Record.<br>No nonsense</h1>
                            <p class="text-base font-normal leading-normal xl:text-lg text-neutral-700">Quick Studio is a collaborative, music recording software focused on bringing an accessible experience for musicians of all levels of experience to turn their ideas into demos.</p>
                            <div class="flex flex-col gap-6 xl:items-baseline lg:flex-row lg:gap-8 xl:gap-10">
                                <span class="text-base font-normal leading-normal xl:text-lg text-neutral-900">Join a new era of music making.</span>
                                <div class="">
                                    <a class="inline-block px-6 py-2 text-base font-semibold transition border rounded-sm ring-primary-200 focus:ring-2 hover:bg-primary-400 text-primary-700 bg-primary border-primary-600" href="#">Register</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Hero Image -->
                    <div class="col-span-4 col-start-5 lg:col-span-6 lg:col-start-7">
                        <div class="flex flex-col justify-center h-full">
                            <picture>
                                <img class="w-full" src="@/assets/instruments.png" alt="Instruments">
                            </picture>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'HeroBlock',
    }
</script>