<template>

    <main class="scroll-smooth selection:bg-primary-500 selection:text-neutral-900">
        
        <NavigationBlock />

        <HeroBlock />

        <AboutBlock />

        <ValuesBlock />

        <TestimonialsBlock />

        <ContactBlock />

        <FooterBlock />
        
    </main>

</template>

<script>

    // Import Components
    import NavigationBlock from './blocks/Navigation'
    import HeroBlock from './blocks/Hero'
    import AboutBlock from './blocks/About'
    import ValuesBlock from './blocks/Values'
    import TestimonialsBlock from './blocks/Testimonials'
    import ContactBlock from './blocks/Contact'
    import FooterBlock from './blocks/Footer'
    
    // Export App
    export default {
        name: 'App',
        components: {
            NavigationBlock,
            HeroBlock,
            AboutBlock,
            ValuesBlock,
            TestimonialsBlock,
            ContactBlock,
            FooterBlock
        }
    }

</script>