<template>
    <section id="Contact">
        <div class="p-8 sm:p-12 lg:p-16">
            <div class="max-w-screen-xl mx-auto">
                <div class="flex flex-col gap-8 lg:gap-0 sm:grid sm:grid-cols-8 lg:grid-cols-12">

                    <!-- Text Area -->
                    <div class="col-span-4 lg:col-span-5">
                        <div class="space-y-4 lg:space-y-6">
                            <!-- Title -->
                            <h2 class="text-4xl font-bold xl:text-5xl text-neutral-900">Get in Touch</h2>
                            <!-- Quote -->
                            <div class="text-base italic xl:text-lg text-neutral-700">
                                <p>"This is all very cool and stuff,<br>but I have several questions"</p>
                            </div>
                            <!-- Text -->
                            <div class="text-base font-medium xl:text-lg text-neutral-800">
                                <p>That's alright, we all have many questions in life, but as long as they're about Quick Studio I think I can help you. Just fill in the form and we will attempt to do reply as soon as possible.</p>
                            </div>
                        </div>
                    </div>

                    <!-- Form Area -->
                    <div class="col-span-4 lg:col-span-6 lg:col-start-7">
                        <form action="#" method="post">
                            <div class="flex flex-col gap-6 lg:gap-8">
                                
                                <!-- Email -->
                                <div class="">
                                    <label for="form-input-email" class="block mb-2 text-base font-semibold xl:text-lg text-neutral-700 required">Email</label>
                                    <input class="block w-full px-6 py-4 text-sm transition border-2 rounded-sm resize-none xl:text-base text-neutral-600 bg-neutral-200 border-neutral-300 hover:bg-primary-200 active:border focus:outline-none focus:border-primary-500" name="email" type="text" id="form-input-email" data-required="">
                                </div>

                                <!-- Message -->
                                <div class="">
                                    <label for="form-input-message" class="block mb-2 text-base font-semibold xl:text-lg text-neutral-700 required">Message</label>
                                    <textarea class="block w-full px-6 py-4 text-sm transition border-2 rounded-sm resize-none xl:text-base text-neutral-600 bg-neutral-200 border-neutral-300 hover:bg-primary-200 active:border focus:outline-none focus:border-primary-500" name="message" id="form-input-message" rows="5" data-required=""></textarea>
                                </div>
                                
                                <!-- Send Button -->
                                <div class="">
                                    <a class="inline-block px-6 py-2 text-base font-semibold transition border rounded-sm ring-primary-200 focus:ring-2 hover:bg-primary-400 text-primary-700 bg-primary border-primary-600" href="#">Submit</a>
                                </div>
                                
                            </div>
                        </form>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'ContactBlock'
    }
</script>