<template>
    <section id="Navigation">
        <div class="p-8 sm:p-12 lg:p-16">
            <div class="max-w-screen-xl mx-auto">
                <header class="flex items-center justify-between">
                    
                    <!-- Logo and Home Button -->
                    <a href="#" class="">
                        <img class="h-6 sm:h-8" src="@/assets/logo_light.svg" alt="Logo">
                    </a>

                    <!-- Desktop Nav -->
                    <nav class="items-baseline hidden gap-10 xl:gap-16 sm:flex">
                        <a class="text-base font-semibold transition xl:text-lg text-neutral-900 hover:text-secondary-700 focus:text-secondary-600" href="#About">About</a>
                        <a class="text-base font-semibold transition xl:text-lg text-neutral-900 hover:text-secondary-700 focus:text-secondary-600" href="#Contact">Contact</a>
                        <a class="text-base font-semibold transition xl:text-lg text-neutral-900 hover:text-secondary-700 focus:text-secondary-600" href="#" target="_blank">App</a>
                        <a class="inline-block px-6 py-2 text-base font-semibold transition border rounded-sm ring-primary-200 focus:ring-2 hover:bg-primary-400 text-primary-700 bg-primary border-primary-600" href="#">Login</a>
                    </nav>

                    <!-- Burger hidden on desktop -->
                    <a href="#" class="transition transform cursor-pointer hover:rotate-90 text-neutral-900 hover:text-neutral-800 sm:hidden">
                        <svg class="w-6 h-6 sm:w-8 sm:h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                    </a>
                    
                </header>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'NavigationBlock'
    }
</script>